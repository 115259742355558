import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "./GalleryContainer.css";

const GalleryContainer = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Fetch images from API and filter highlighted ones
        const fetchHighlightedImages = async () => {
            try {
                const response = await axios.get("https://jciamravati.in/api/v1/gallery/images");
                // Check if the API returns images as an array
                if (response.data && Array.isArray(response.data.images)) {
                    // Filter images that are highlighted
                    const highlightedImages = response.data.images.filter(
                        (img) => img.highlighted
                    );
                    // Create the full image URLs using the base URL from your media server
                    const baseUrl =
                        "https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati";
                    const mappedImages = highlightedImages.map((img) => ({
                        url: `${baseUrl}/${img.name}?q=50%&&f=webp`,
                        id: img.id,
                        name: img.name,
                    }));
                    setImages(mappedImages);
                } else {
                    console.error("Invalid response format", response.data);
                }
            } catch (error) {
                console.error("Error fetching highlighted images:", error);
            }
        };

        fetchHighlightedImages();
    }, []);

    return (
        <div className="container-fluid gallery-container py-5">
            <div className="container gallery-content text-center mb-4">
                <h4>IMAGES GALLERY</h4>
            </div>
            <div className="container gallery-image">
                <div className="row">
                    {images.map((image) => (
                        <div key={image.id} className="col-6 col-md-4 col-lg-3 mb-4">
                            <div className="img-wrapper">
                                <img
                                    src={image.url}
                                    alt="gallery-image"
                                    className="img-fluid rounded shadow-sm"
                                />
                            </div>
                        </div>
                    ))}
                    <div className="viewbtn col-12 mt-3 text-center border-rounded">
                        <NavLink
                            to="/gallery"
                            className="know-btn"
                            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                        >
                            View More
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GalleryContainer;
