import React, { useState, useEffect } from "react";
import axios from "axios";
import WOW from "wowjs";
import { NavLink } from "react-router-dom";
import "./YtContainer.css";

// Updated extractYoutubeId to support YouTube Shorts
function extractYoutubeId(url) {
    if (!url) return "";
    // Check for YouTube Shorts URL pattern
    const shortsMatch = url.match(/\/shorts\/([^?]+)/);
    if (shortsMatch && shortsMatch[1]) return shortsMatch[1];
    // Handle shortened youtu.be URLs
    const shortUrlMatch = url.match(/youtu\.be\/([^?]+)/);
    if (shortUrlMatch && shortUrlMatch[1]) return shortUrlMatch[1];
    // Handle full-length URLs with "v" parameter
    const regex = /[?&]v=([^&#]*)/;
    const match = url.match(regex);
    if (match && match[1]) return match[1];
    // Fallback: return the last segment
    const parts = url.split("/");
    return parts[parts.length - 1];
}

const YtContainer = () => {
    const [youtubeVideos, setYoutubeVideos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    useEffect(() => {
        // Fetch all YouTube URLs and filter for highlighted ones
        const fetchHighlightedVideos = async () => {
            try {
                const response = await axios.get("https://jciamravati.in/api/v1/youtube/get");
                // Expecting response.data.data to be an array of objects with { id, url, highlighted }
                const allVideos = response.data.data || [];
                const highlightedVideos = allVideos.filter(video => video.highlighted === true);
                setYoutubeVideos(highlightedVideos);
            } catch (error) {
                console.error("Error fetching YouTube URLs:", error);
            }
            setLoading(false);
        };

        fetchHighlightedVideos();
    }, []);

    return (
        <div className="container-fluid parent-yt-container py-5">
            <div className="container">
                <div className="text-center mb-5">
                    <h4 className="gallery-title">VIDEO GALLERY</h4>
                </div>
                {loading ? (
                    <p className="text-center">Loading highlighted videos...</p>
                ) : (
                    <>
                        {youtubeVideos.length > 0 ? (
                            <div className="row">
                                {youtubeVideos.map((video) => {
                                    const videoId = extractYoutubeId(video.url);
                                    return (
                                        <div key={video.id} className="col-12 col-sm-6 col-md-4 mb-4">
                                            <div className="card video-gallery-card h-100">
                                                <a href={video.url} target="_blank" rel="noopener noreferrer">
                                                    <img
                                                        src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                                                        alt="Highlighted Video Thumbnail"
                                                        className="card-img-top video-thumbnail"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <p className="text-center">No highlighted videos available.</p>
                        )}
                        <div className="viewbtn col-12 mt-3 text-center border-rounded">
                            <NavLink
                                to="/gallery"
                                className="know-btn"
                                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                            >
                                View More
                            </NavLink>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default YtContainer;
