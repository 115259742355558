import './Gallery.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

// Helper function to extract YouTube video ID (supports Shorts, youtu.be, etc.)
const extractYoutubeId = (url) => {
  if (!url) return '';
  // Match YouTube Shorts
  const shortsMatch = url.match(/\/shorts\/([^?]+)/);
  if (shortsMatch && shortsMatch[1]) return shortsMatch[1];

  // Match youtu.be short links
  const shortUrlMatch = url.match(/youtu\.be\/([^?]+)/);
  if (shortUrlMatch && shortUrlMatch[1]) return shortUrlMatch[1];

  // Match normal YouTube links (?v=...)
  const regex = /[?&]v=([^&#]*)/;
  const match = url.match(regex);
  if (match && match[1]) return match[1];

  // Fallback: last part of the URL
  const parts = url.split('/');
  return parts[parts.length - 1];
};

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [activeMedia, setActiveMedia] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  // Fetch images
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://jciamravati.in/api/v1/gallery/images');
        const baseUrl =
            'https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati';
        if (response.data.images && Array.isArray(response.data.images)) {
          setImages(
              response.data.images.map((img) => ({
                url: `${baseUrl}/${img.name}?q=50%&&f=webp`,
                id: img.id,
                name: img.name,
              }))
          );
        }
      } catch (error) {
        console.error('Error fetching gallery images:', error.message);
      }
    };
    fetchImages();
  }, []);

  // Fetch videos
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('https://jciamravati.in/api/v1/youtube/get');
        // API returns { data: [ ... ] } in response.data.data
        setVideos(response.data.data || []);
      } catch (error) {
        console.error('Error fetching gallery videos:', error.message);
      }
    };
    fetchVideos();
  }, []);

  // Open lightbox with selected media
  const openMedia = (type, index) => {
    setActiveMedia({ type, index });
    setIsOpen(true);
  };

  // Close the lightbox
  const closeModal = () => {
    setIsOpen(false);
    setActiveMedia(null);
  };

  // Go to previous item
  const prevMedia = (e) => {
    e.stopPropagation(); // prevent closing the modal by clicking background
    if (!activeMedia) return;

    if (activeMedia.type === 'image') {
      setActiveMedia((prev) => {
        const newIndex = (prev.index - 1 + images.length) % images.length;
        return { ...prev, index: newIndex };
      });
    } else {
      setActiveMedia((prev) => {
        const newIndex = (prev.index - 1 + videos.length) % videos.length;
        return { ...prev, index: newIndex };
      });
    }
  };

  // Go to next item
  const nextMedia = (e) => {
    e.stopPropagation();
    if (!activeMedia) return;

    if (activeMedia.type === 'image') {
      setActiveMedia((prev) => {
        const newIndex = (prev.index + 1) % images.length;
        return { ...prev, index: newIndex };
      });
    } else {
      setActiveMedia((prev) => {
        const newIndex = (prev.index + 1) % videos.length;
        return { ...prev, index: newIndex };
      });
    }
  };

  return (
      <div className="container-fluid gallery-parent">
        <div className="container gallery-child">
          {/* IMAGE GALLERY */}
          <h2>Image Gallery</h2>
          <div className="row">
            {images.length === 0 ? (
                <p>No images available.</p>
            ) : (
                images.map((img, index) => (
                    <div key={img.id} className="col-6 col-sm-6 col-md-3 p-2">
                      <img
                          className="gallery-thumbnail"
                          src={img.url}
                          alt={img.name}
                          onClick={() => openMedia('image', index)}
                      />
                    </div>
                ))
            )}
          </div>

          {/* VIDEO GALLERY */}
          <h2>Video Gallery</h2>
          <div className="row">
            {videos.length === 0 ? (
                <p>No videos available.</p>
            ) : (
                videos.map((video, index) => {
                  const videoId = extractYoutubeId(video.url);
                  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
                  return (
                      <div key={video.id} className="col-6 col-sm-6 col-md-3 p-2">
                        <img
                            className="gallery-thumbnail"
                            src={thumbnailUrl}
                            alt={`Video ${index + 1}`}
                            onClick={() => openMedia('video', index)}
                        />
                      </div>
                  );
                })
            )}
          </div>
        </div>

        {/* LIGHTBOX MODAL */}
        {isOpen && activeMedia && (
            <div className="lightbox-overlay" onClick={closeModal}>
              <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
                {/* PREV BUTTON */}
                <button className="lightbox-prev" onClick={prevMedia}>
                  <ChevronLeft size={32} />
                </button>

                {/* MAIN MEDIA */}
                {activeMedia.type === 'image' ? (
                    <img
                        src={images[activeMedia.index]?.url}
                        alt="Lightbox"
                        className="lightbox-img"
                    />
                ) : (
                    <iframe
                        title="YouTube Video"
                        src={`https://www.youtube.com/embed/${extractYoutubeId(
                            videos[activeMedia.index]?.url
                        )}?autoplay=1`}
                        allowFullScreen
                        className="lightbox-iframe"
                    ></iframe>
                )}

                {/* NEXT BUTTON */}
                <button className="lightbox-next" onClick={nextMedia}>
                  <ChevronRight size={32} />
                </button>

                {/* CLOSE BUTTON */}
                <button className="lightbox-close" onClick={closeModal}>
                  <X size={24} />
                </button>
              </div>
            </div>
        )}
      </div>
  );
};

export default Gallery;
