import React, { useEffect, useState } from "react";
import "animate.css";
import WOW from "wowjs";
import BoardCard from "./BoardCard";
import "./SuperHeroes.css";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

function SuperHeroes() {
    const [members, setMembers] = useState([]);

    useEffect(() => {
        // Initialize WOW.js for animations
        const wow = new WOW.WOW({ live: false });
        wow.init();

        // Fetch members data from API
        const fetchMembers = async () => {
            try {
                const response = await axios.get("https://jciamravati.in/api/v1/team/getteam");
                // Filter only those with role "JCI Superheroes 2025"
                const allMembers = response.data.data.filter(
                    (member) => member.role === "JCI Superheroes 2025"
                );
                setMembers(allMembers);
            } catch (error) {
                console.error("Error fetching members data:", error);
            }
        };

        fetchMembers();
    }, []);

    // Separate the President from the list
    const president = members.find((member) => member.post === "President");

    // Filter and sort Executives (IPP, Treasurer, Secretary) in the desired order
    const executiveOrder = ["IPP", "Treasurer", "Secretary"];
    const executives = members
        .filter((member) => executiveOrder.includes(member.post))
        .sort((a, b) => executiveOrder.indexOf(a.post) - executiveOrder.indexOf(b.post));

    // The rest of the members (neither President nor Executives)
    const otherMembers = members.filter(
        (member) => member.post !== "President" && !executiveOrder.includes(member.post)
    );

    // Sort other members by id to ensure a consistent sequence
    const sortedOtherMembers = [...otherMembers].sort((a, b) => a.id - b.id);

    return (
        <Container className="zone-container text-center">
            {/* President Section */}
            {president && (
                <Row className="justify-content-center">
                    <Col xs={12} sm={8} md={6} lg={4} className="wow animate__animated animate__fadeIn">
                        <BoardCard
                            name={president.name}
                            title={president.post}
                            image={`https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati/${president.imagename.split(",")[0]}`}
                        />
                    </Col>
                </Row>
            )}

            {/* Executives Section: IPP, Treasurer, Secretary */}
            {executives.length > 0 && (
                <Row className="justify-content-center mb-4">
                    {executives.map((member, index) => (
                        <Col key={index} xs={12} sm={4} md={4} lg={3} className="wow animate__animated animate__fadeIn mb-4">
                            <BoardCard
                                name={member.name}
                                title={member.post}
                                image={`https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati/${member.imagename.split(",")[0]}`}
                            />
                        </Col>
                    ))}
                </Row>
            )}

            {/* Title and Section for Other LGB Members */}
            {sortedOtherMembers.length > 0 && (
                <>
                    <Row className="justify-content-center mb-2">
                        <Col xs={12}>
                            <h2>LGB Members</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        {sortedOtherMembers.map((member, index) => (
                            <Col key={index} xs={6} sm={4} md={4} lg={3} className="wow animate__animated animate__fadeIn mb-4">
                                <BoardCard
                                    name={member.name}
                                    title={member.post}
                                    image={`https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati/${member.imagename.split(",")[0]}`}
                                />
                            </Col>
                        ))}
                    </Row>
                </>
            )}

            {members.length === 0 && <p className="text-muted">No Members available.</p>}
        </Container>
    );
}

export default SuperHeroes;
