import React, { useState } from 'react';

import "./privacy.css"

const Privacy = () => {
    // State to track which section is expanded (null means none)
    const [expandedSection, setExpandedSection] = useState(null);

    // Toggle the section based on its index
    const toggleSection = (index) => {
        setExpandedSection(expandedSection === index ? null : index);
    };

    // All sections with titles and corresponding content
    const sections = [
        {
            title: "1. WHAT INFORMATION DO WE COLLECT?",
            content: (
                <div>
                    <h5>Personal information you disclose to us</h5>
                    <p className="mb-3">
                        <strong>In Short:</strong> We collect personal information that you provide to us.
                    </p>
                    <p>
                        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                    </p>
                    <p>
                        <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                    </p>
                    <ul>
                        <li>names</li>
                        <li>phone numbers</li>
                        <li>email addresses</li>
                        <li>mailing addresses</li>
                        <li>usernames</li>
                        <li>contact or authentication data</li>
                        <li>billing addresses</li>
                        <li>job titles</li>
                        <li>passwords</li>
                        <li>contact preferences</li>
                        <li>debit/credit card numbers</li>
                    </ul>
                    <p>
                        <strong>Sensitive Information.</strong> We do not process sensitive information.
                    </p>
                    <p>
                        <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Razorpay. You may find their privacy notice link(s) here:{" "}
                        <a href="https://razorpay.com/privacy/" target="_blank" rel="noopener noreferrer">
                            https://razorpay.com/privacy/
                        </a>.
                    </p>

                    <h5 className="mt-4">Application Data</h5>
                    <p>
                        If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
                    </p>
                    <ul>
                        <li>
                            <strong>Geolocation Information.</strong> We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                        </li>
                        <li>
                            <strong>Mobile Device Access.</strong> We may request access or permission to certain features from your mobile device, including your mobile device's camera, contacts, SMS messages, storage, calendar, reminders, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
                        </li>
                        <li>
                            <strong>Mobile Device Data.</strong> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model, Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server).
                        </li>
                        <li>
                            <strong>Push Notifications.</strong> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
                        </li>
                    </ul>

                    <h5 className="mt-4">Information automatically collected</h5>
                    <p className="mb-3">
                        <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                    </p>
                    <p>
                        We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                    </p>
                    <p>
                        Like many businesses, we also collect information through cookies and similar technologies.
                    </p>
                    <p>The information we collect includes:</p>
                    <ul>
                        <li>
                            <strong>Log and Usage Data.</strong> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files.
                        </li>
                        <li>
                            <strong>Device Data.</strong> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services.
                        </li>
                        <li>
                            <strong>Location Data.</strong> We collect location data such as information about your device's location, which can be either precise or imprecise.
                        </li>
                    </ul>
                </div>
            )
        },
        {
            title: "2. HOW DO WE PROCESS YOUR INFORMATION?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                    </p>
                    <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                    <ul>
                        <li>To facilitate account creation and authentication and otherwise manage user accounts</li>
                        <li>To deliver and facilitate delivery of services to the user</li>
                        <li>To respond to user inquiries/offer support to users</li>
                        <li>To send administrative information to you</li>
                        <li>To fulfill and manage your orders</li>
                        <li>To enable user-to-user communications</li>
                        <li>To request feedback</li>
                        <li>To send you marketing and promotional communications</li>
                        <li>To deliver targeted advertising to you</li>
                        <li>To protect our Services</li>
                        <li>To identify usage trends</li>
                        <li>To determine the effectiveness of our marketing and promotional campaigns</li>
                        <li>To save or protect an individual's vital interest</li>
                    </ul>
                </div>
            )
        },
        {
            title: "3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
                    </p>

                    <h5>If you are located in the EU or UK, this section applies to you.</h5>
                    <p>
                        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                    </p>
                    <ul>
                        <li>
                            <strong>Consent</strong>
                        </li>
                        <li>
                            <strong>Performance of a Contract</strong>
                        </li>
                        <li>
                            <strong>Legitimate Interests</strong>
                        </li>
                        <li>
                            <strong>Legal Obligations</strong>
                        </li>
                        <li>
                            <strong>Vital Interests</strong>
                        </li>
                    </ul>

                    <h5 className="mt-4">If you are located in Canada, this section applies to you.</h5>
                    <p>
                        We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.
                    </p>
                    <p>
                        In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent.
                    </p>
                </div>
            )
        },
        {
            title: "4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.
                    </p>
                    <p>We may need to share your personal information in the following situations:</p>
                    <ul>
                        <li>
                            <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        </li>
                        <li>
                            <strong>When we use Google Maps Platform APIs.</strong> We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API).
                        </li>
                        <li>
                            <strong>Other Users.</strong> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity.
                        </li>
                    </ul>
                </div>
            )
        },
        {
            title: "5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.
                    </p>
                    <p>
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
                    </p>
                </div>
            )
        },
        {
            title: "6. HOW LONG DO WE KEEP YOUR INFORMATION?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                    </p>
                    <p>
                        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
                    </p>
                    <p>
                        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                    </p>
                </div>
            )
        },
        {
            title: "7. HOW DO WE KEEP YOUR INFORMATION SAFE?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.
                    </p>
                    <p>
                        We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                    </p>
                </div>
            )
        },
        {
            title: "8. DO WE COLLECT INFORMATION FROM MINORS?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.
                    </p>
                    <p>
                        We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at           jciamravati1959@gmail.com
                    </p>
                </div>
            )
        },
        {
            title: "9. WHAT ARE YOUR PRIVACY RIGHTS?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
                    </p>
                    <p>
                        In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
                    </p>
                    <p>
                        We will consider and act upon any request in accordance with applicable data protection laws.
                    </p>
                    <p>
                        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.
                    </p>
                    <p>
                        If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
                    </p>
                    <h5 className="mt-4">Account Information</h5>
                    <p>
                        If you would at any time like to review or change the information in your account or terminate your account, you can:
                    </p>
                    <ul>
                        <li>Log in to your account settings and update your user account</li>
                        <li>Contact us using the contact information provided</li>
                    </ul>
                    <p>
                        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                    </p>
                </div>
            )
        },
        {
            title: "10. CONTROLS FOR DO-NOT-TRACK FEATURES",
            content: (
                <div>
                    <p>
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                    </p>
                </div>
            )
        },
        {
            title: "11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                    </p>
                    <p>
                        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                    </p>
                    <p>
                        If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
                    </p>
                    <h5 className="mt-4">CCPA Privacy Notice</h5>
                    <p>The California Code of Regulations defines a "resident" as:</p>
                    <ol type="1">
                        <li>every individual who is in the State of California for other than a temporary or transitory purpose and</li>
                        <li>every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
                    </ol>
                    <p>All other individuals are defined as "non-residents."</p>
                    <p>
                        If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
                    </p>
                </div>
            )
        },
        {
            title: "12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.
                    </p>
                    <h5>Virginia CDPA Privacy Notice</h5>
                    <p>
                        Under the Virginia Consumer Data Protection Act (CDPA):
                    </p>
                    <p>
                        "Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.
                    </p>
                    <p>
                        "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.
                    </p>
                    <p>
                        "Sale of personal data" means the exchange of personal data for monetary consideration.
                    </p>
                    <p>
                        If this definition "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.
                    </p>
                </div>
            )
        },
        {
            title: "13. DO WE MAKE UPDATES TO THIS NOTICE?",
            content: (
                <div>
                    <p className="mb-3">
                        <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                    </p>
                    <p>
                        We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                    </p>
                </div>
            )
        },
        {
            title: "14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
            content: (
                <div>
                    <p>
                        If you have questions or comments about this notice, you may email us at{" "}
                        <a href="mailto:  jciamravati1959@gmail.com">  jciamravati1959@gmail.com</a> or by post to:
                    </p>
                    <address>
                        Junior Chamber International India<br />
                        National Head Quarter 506 Windfall,<br />
                        Sahar Plaza, J.B. Nagar, Andheri (EAST)<br />
                        Mumbai, Maharastra 400059<br />
                        India
                    </address>
                </div>
            )
        },
        {
            title: "15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?",
            content: (
                <div>
                    <p>
                        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.
                    </p>
                </div>
            )
        }
    ];

    return (
        <div className="container-fluid privacy-parent">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="card">
                        <div className="card-body">
                            {/* Header */}
                            <h1 className="text-center mb-4">Privacy Policy</h1>
                            <p className="text-muted text-center mb-4">Last updated February 20, 2025</p>

                            {/* Introduction */}
                            <div className="mb-4">
                                <p>
                                    This privacy notice for Junior Chamber International Amravati (doing business as JCI Amravati) ("JCI Amravati," "we," "us," or "our") describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
                                </p>
                                <ul>
                                    <li>
                                        Visit our website at{" "}
                                        <a href="http://www.jciamravati.in" target="_blank" rel="noopener noreferrer">
                                            http://www.jciamravati.in
                                        </a>, or any website of ours that links to this privacy notice
                                    </li>
                                    <li>Download and use our mobile application (JCOM), or any other application of ours that links to this privacy notice</li>
                                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                                </ul>
                                <p className="mt-3">
                                    <strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at{" "}
                                    <a href="mailto:  jciamravati1959@gmail.com">  jciamravati1959@gmail.com</a>.
                                </p>
                            </div>

                            {/* Summary of Key Points */}
                            <div className="card mb-4">
                                <div className="card-header bg-light">
                                    <h4 className="mb-0">SUMMARY OF KEY POINTS</h4>
                                </div>
                                <div className="card-body">
                                    <p>
                                        This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.
                                    </p>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with JCI Amravati and the Services, the choices you make, and the products and features you use.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Do we receive any information from third parties?</strong> We do not receive any information from third parties.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>How do we keep your information safe?</strong> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* Table of Contents */}
                            <div className="mb-4">
                                <h4>TABLE OF CONTENTS</h4>
                                <div className="list-group">
                                    {sections.map((section, index) => (
                                        <button
                                            key={index}
                                            className="list-group-item list-group-item-action text-primary"
                                            onClick={() => toggleSection(index)}
                                            aria-expanded={expandedSection === index}
                                        >
                                            {section.title}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {/* Render each section (visible only if expanded) */}
                            {sections.map((section, index) => (
                                <div key={index} className={`mb-4 ${expandedSection === index ? '' : 'd-none'}`}>
                                    <div className="card">
                                        <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                                            <h4 className="mb-0">{section.title}</h4>
                                            <button className="btn btn-sm btn-light" onClick={() => toggleSection(index)}>
                                                Close
                                            </button>
                                        </div>
                                        <div className="card-body">{section.content}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
