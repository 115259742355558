import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./ProgramsContainer.css";

const ProgramsContainer = () => {
    const [submittedData, setSubmittedData] = useState([]);
    const navigate = useNavigate();

    // Fetch data from the API and attach image URLs
    const fetchData = async () => {
        try {
            const response = await axios.get(
                "https://jciamravati.in/api/v1/workingareas/getrecord"
            );
            // Filter to get only highlighted programs
            const highlightedData = response.data.data.filter(
                (item) => item.highlighted === true
            );
            // For each program, attach image URLs
            const dataWithImages = await Promise.all(
                highlightedData.map(async (program) => {
                    const imageUrls = await fetchImageUrls(program.imagename);
                    return { ...program, imageUrls };
                })
            );
            setSubmittedData(dataWithImages || []);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Build full image URLs based on the comma-separated image names
    const fetchImageUrls = async (imagename) => {
        try {
            const baseUrl =
                "https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati";
            const imageNames = imagename.split(",");
            const mappedImages = imageNames.map((name) => ({
                url: `${baseUrl}/${name}?q=50%&&f=webp`,
                id: name,
            }));
            return mappedImages;
        } catch (err) {
            console.error("Error fetching image URLs:", err);
            return [];
        }
    };

    // Determine the route based on the working area
    const getRouteForWorkingArea = (workingarea) => {
        switch (workingarea.toLowerCase()) {
            case "management":
                return "/Workmanagement";
            case "business":
                return "/Workbusiness";
            case "community":
                return "/Workcommunity";
            case "international growth and development":
                return "/Workdevelopment";
            case "training":
                return "/Worktraining";
            default:
                return "/";
        }
    };

    // Helper function to format date as dd-mm-yyyy
    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, "0");
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Navigate to the appropriate working area detail page,
    // passing along the program data and flag to open the full table data
    const handleViewMore = (program) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        const route = getRouteForWorkingArea(program.workingarea);
        navigate(route, { state: { program, openTable: true } });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="container-fluid programs-parent-container py-5">
            <Container className="programs-child-container">
                <h3 className="mb-4 text-center">HIGHLIGHTED ACTIVITIES</h3>
                <Row className="g-4">
                    {submittedData.length === 0 ? (
                        <p className="text-center">No Highlights</p>
                    ) : (
                        submittedData.map((program, index) => (
                            <Col key={index} xs={12} sm={6} md={4}>
                                <Card className="h-100 card-component shadow-sm ">
                                    {/* Working area as top text */}
                                    {/*<div className="py-2 text-center">
                                        {program.workingarea}
                                    </div>*/}
                                    {program.imageUrls && program.imageUrls.length > 0 ? (
                                        <Card.Img
                                            variant="top"
                                            src={program.imageUrls[0].url}
                                            alt={program.title}
                                            style={{ height: "220px", objectFit: "cover" }}
                                        />
                                    ) : (
                                        <Card.Img
                                            variant="top"
                                            src="/assets/images/landscape-image.jpg"
                                            alt="Fallback"
                                            style={{ height: "120px", objectFit: "cover" }}
                                        />
                                    )}
                                    <Card.Body>
                                        {/* Title in bold without label */}
                                        <Card.Title className="fw-bold">{program.title}</Card.Title>
                                        <Card.Text>
                                            {program.location} <br />
                                            {program.time} <br />
                                            {formatDate(program.date)}
                                        </Card.Text>
                                        <Button variant="warning" onClick={() => handleViewMore(program)}>
                                            View More
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default ProgramsContainer;
