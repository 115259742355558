import { useState, useEffect, useCallback, useMemo } from "react";
import "./Workareas.css";
import { ChevronLeft } from "lucide-react";

const ActivityDetails = ({ activity, onClose }) => {
    const [imageUrls, setImageUrls] = useState([]);
    const [youtubeThumbnails, setYoutubeThumbnails] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const formatActivityDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        if (activity.imagename) {
            const baseUrl = "https://media.bizonance.in/api/v1/image/download/eca82cda-d4d7-4fe5-915a-b0880bb8de74/jci-amravati";
            const imageNames = activity.imagename.split(",");
            const mappedImages = imageNames.map((name) => ({
                url: `${baseUrl}/${name.trim()}?q=50&&f=webp`,
                id: name.trim(),
            }));
            setImageUrls(mappedImages);
        }

        if (activity.youtubeUrls && activity.youtubeUrls.length > 0) {
            const youtubeLinks = Array.isArray(activity.youtubeUrls)
                ? activity.youtubeUrls
                : JSON.parse(activity.youtubeUrls);

            const thumbnails = youtubeLinks.map((url) => {
                const urlObj = new URL(url);
                let videoId = urlObj.searchParams.get("v");

                if (!videoId && urlObj.hostname === "youtu.be") {
                    videoId = urlObj.pathname.slice(1);
                }

                if (!videoId && urlObj.pathname.includes("/embed/")) {
                    const parts = urlObj.pathname.split("/embed/");
                    if (parts[1]) videoId = parts[1].split("/")[0];
                }

                if (!videoId && urlObj.pathname.includes("shorts")) {
                    const parts = urlObj.pathname.split("/");
                    const shortsIndex = parts.indexOf("shorts");
                    if (shortsIndex !== -1) videoId = parts[shortsIndex + 1];
                }

                const embedUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : null;
                const thumbnail = videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : "";

                return { id: videoId, thumbnail, embedUrl };
            });

            setYoutubeThumbnails(thumbnails);
        }
    }, [activity]);

    return (
        <div className="activity-details p-0 p-md-4 rounded">
            <div className="activity-header d-flex align-items-center mb-3 mb-md-4">
                <button onClick={onClose} className="btn work-button me-2">
                    <ChevronLeft size={20} />
                </button>
                <h1 className="text-uppercase m-0 text-center flex-grow-1 fs-5 fs-md-3">
                    {activity.title}
                </h1>
            </div>

            <div className="activity-info text-secondary">
                <div className="d-flex flex-column flex-md-row mb-4 w-100 w-md-50 mx-auto justify-content-center gap-2 gap-md-5 text-center activity-title">
                    <p className="fs-6 fs-md-5 fw-semibold">
                        {formatActivityDate(activity.date)}
                    </p>
                    <p className="fs-6 fs-md-5 fw-semibold"> {activity.time}</p>
                    <p className="fs-6 fs-md-5 fw-semibold">{activity.location}</p>
                </div>

                <div className="activity-description text-white">
                    {activity.description.split("\n").map((paragraph, index) => (
                        <p key={index} className="fs-6 fs-md-5">
                            {paragraph}
                        </p>
                    ))}
                </div>

                {imageUrls.length > 0 && (
                    <div className="activity-images mt-4 ">
                        <h3 className="text-center mb-3 fs-5 fs-md-4">Images</h3>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 justify-content-center">
                            {imageUrls.map((image, index) => (
                                <div key={index} className="col">
                                    <img
                                        src={image.url}
                                        alt={`Activity Image ${index + 1}`}
                                        className="img-fluid rounded shadow-sm"
                                        style={{ height: "200px", objectFit: "cover" }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {youtubeThumbnails.length > 0 && (
                    <div className="youtube-thumbnails mt-4">
                        <h3 className="text-center mb-3 fs-5 fs-md-4">Videos</h3>
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 justify-content-center">
                            {youtubeThumbnails.map((video) => (
                                <div key={video.id} className="col">
                                    <img
                                        src={video.thumbnail}
                                        alt="YouTube Thumbnail"
                                        className="img-fluid rounded shadow-sm video-thumbnail"
                                        style={{ height: "250px", objectFit: "cover" }}
                                        onClick={() => setSelectedVideo(video.embedUrl)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {selectedVideo && (
                    <div className="youtube-lightbox">
                        <div className="overlay" onClick={() => setSelectedVideo(null)}></div>
                        <div className="lightbox-content">
                            <div className="ratio ratio-16x9">
                                <iframe
                                    src={selectedVideo}
                                    title="YouTube video"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <button
                                className="close-lightbox"
                                onClick={() => setSelectedVideo(null)}
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <style jsx>{`
                .youtube-lightbox {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                    animation: fadeIn 0.3s;
                }
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.8);
                }
                .lightbox-content {
                    position: relative;
                    z-index: 1001;
                    background: #fff;
                    padding: 16px;
                    border-radius: 12px;
                    width: 95%;
                    max-width: 800px;
                    animation: zoomIn 0.3s;
                }
                .close-lightbox {
                    position: absolute;
                    top: -12px;
                    right: -12px;
                    background: #f00;
                    color: #fff;
                    border: none;
                    border-radius: 50%;
                    font-size: 20px;
                    width: 34px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
                @keyframes zoomIn {
                    from { transform: scale(0.8); }
                    to { transform: scale(1); }
                }
                @media (max-width: 768px) {
                    .lightbox-content {
                        padding: 8px;
                    }
                    .close-lightbox {
                        top: -8px;
                        right: -8px;
                        width: 28px;
                        height: 28px;
                        font-size: 16px;
                    }
                }
            `}</style>
        </div>
    );
};

const Workbusiness = () => {
    const [filter, setFilter] = useState("all");
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);

    const fetchActivities = useCallback(async () => {
        try {
            const response = await fetch("https://jciamravati.in/api/v1/workingareas/getBusiness");
            if (!response.ok) throw new Error("Failed to fetch activities");
            const data = await response.json();
            setActivities(data.data || []);
        } catch (err) {
            console.error("Error fetching activities:", err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);

    const formatDate = (dateStr) => {
        if (!dateStr) return "N/A";
        const parts = dateStr.split("-");
        return parts.length === 3 ? `${parts[2]}-${parts[1]}-${parts[0]}` : dateStr;
    };

    const uniqueYears = useMemo(() => {
        const years = new Set();
        activities.forEach((activity) => {
            const year = new Date(activity.date).getFullYear();
            years.add(year);
        });
        return Array.from(years).sort((a, b) => b - a);
    }, [activities]);

    const filteredActivities = useMemo(() => {
        return filter === "all"
            ? activities
            : activities.filter(item =>
                new Date(item.date).getFullYear().toString() === filter
            );
    }, [activities, filter]);

    return (
        <div className="container-fluid workmanagement-parent py-3 py-md-5">
            <div className="container">
                {selectedActivity ? (
                    <ActivityDetails
                        activity={selectedActivity}
                        onClose={() => setSelectedActivity(null)}
                    />
                ) : (
                    <div className="text-center">
                        <h2 className="mb-3 mb-md-4 fs-4 fs-md-2">WORKING AREA: BUSINESS</h2>
                        <div className="d-flex justify-content-center mb-3 mb-md-4 flex-wrap gap-2">
                            <button
                                className={`work-button ${filter === "all" ? "active" : ""}`}
                                onClick={() => setFilter("all")}
                            >
                                All Activities
                            </button>
                            {uniqueYears.map((year) => (
                                <button
                                    key={year}
                                    className={`work-button ${filter === year.toString() ? "active" : ""}`}
                                    onClick={() => setFilter(year.toString())}
                                >
                                    {year}
                                </button>
                            ))}
                        </div>
                        {loading ? (
                            <p className="text-muted">Loading activities...</p>
                        ) : error ? (
                            <p className="text-danger">{error}</p>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-striped table-hover shadow-sm rounded">
                                    <thead className="table-primary">
                                    <tr>
                                        <th className="py-2 py-md-3">Date</th>
                                        <th className="py-2 py-md-3">Event</th>
                                        <th className="py-2 py-md-3">Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredActivities.map((activity, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">
                                                {activity.date ? formatDate(activity.date) : "N/A"}
                                            </td>
                                            <td className="align-middle">
                                                {activity.title || "Untitled"}
                                            </td>
                                            <td className="align-middle">
                                                <button
                                                    onClick={() => setSelectedActivity(activity)}
                                                    className="btn btn-link p-0"
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Workbusiness;