import React, { useEffect } from "react";
import 'animate.css';
import WOW from 'wowjs';
import grouppng from './assets/images/About-group.jpeg';
import riseup from './assets/images/JCI Rise Up 2025 .png';
import tickpng from './assets/images/check.png';
import founder from './assets/images/founder.jpg';
import quotepng from './assets/images/quote.png';


// import Counter from './Counter';
import './About.css'

const About = () => {


    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
      }, []);

    return (
            <div className="container-fluid about-parent-container d-flex justify-content-center">

                <div className="container about-child-container text-justify">

                    <div className="row about-content">



                        <div className="col-12 col-lg-6 group-text-content text-justify wow animate__animated animate__fadeInLeft">
                            <h4 className="mb-4">ABOUT US</h4>
                            <h3>How it all began!</h3>
                            <p>Junior Chamber International (JCI) is a worldwide federation of young leaders and entrepreneurs with nearly five lakh active members and millions of alumni spread across more than 115 countries.</p>
                            <div className="tick-text">
                                <img src={tickpng} alt="tick" className="tickpng"/><p>JCI orgin can be traced back to 1915 to the city of St. Louis, Missouri, USA where Sir Henry Giessenbier with 32 other young men established Young Men's Progressive Civic Association (YMPCA)</p>
                            </div>
                            <div className="tick-text">
                                <img src={tickpng} alt="tick" className="tickpng"/><p>In 1916, the name was changed from YMPCA to "Junior Citizens", commonly called "JCs", which later became "Jaycees"</p>
                            </div>
                            <div className="tick-text">
                                <img src={tickpng} alt="tick" className="tickpng"/><p>Their work so impressed the St. Louis Junior chamber of Commerce that they asked these young men in 1918 to adopt the name “Junior Chamber of Commerce” which later became “Junior Chamber”</p>
                            </div>
                            <div className="tick-text">
                                <img src={tickpng} alt="tick" className="tickpng"/><p>By 1944, the movement had spread through 18 countries officially forming "Junior Chamber International", welcoming people between age 18 and 40 years</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 group-img-content wow animate__animated animate__fadeInRight">

                            <img src={riseup} alt="Logo" className="grouppng" />
                            {/* <p>Spread across in countries</p> */}

                            {/* <Counter/> */}

                        </div>

                    </div>

                    <div className="founder-content">
                        <div className="row">
                            <div className="col-12 col-lg-4 founderimg wow animate__animated animate__fadeInLeft">
                            <img src={founder} alt="founder" className="founder"/>
                            </div>
                            <div className="col-12 col-lg-8 founder-text text-justify wow animate__animated animate__fadeInRight">
                                <p>"From withi the walls of the soul of this organization wherein the foundation of character and good citizenship are laid, I hope a message will come in the sometime of tomorrow that will stir the people towards the establishment of a permanent and everlasting world peace."</p>
                                <div className="founder-detail">

                                        <img src={quotepng} alt="quote" className="quotepng" />
                                    <div className="detail-text text-left">
                                        <h4>Sir Henry Giessenbier</h4>
                                        <p>Founder</p>


                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                    <div className="believe-content wow animate__animated animate__fadeInUp">   
                        
                        <h4 className="mb-4">JCI CREED</h4>
                        <h2 className="mb-4">We BELIEVE</h2>

                            {/* <div className="col-12 col-lg-6 believe-title">
                                <h2>We BELIEVE</h2>
                            </div> */}
                        <div className="row believe-title">
                            <div className="col-12 col-lg-6 believe-text">
                                <div className="believe-tick-text">
                                    <img src={tickpng} alt="" className="tickpng"/><p>That faith in god gives meaning and purpose to human life,</p>
                                </div>
                                <div className="believe-tick-text">
                                    <img src={tickpng} alt="" className="tickpng"/><p>That the brotherhood of man transcends the sovereignty of nations,</p>
                                </div>
                                <div className="believe-tick-text">
                                    <img src={tickpng} alt="" className="tickpng"/><p>That economic justice can best be won by free men through free enterprise,</p>
                                </div>
   
                            </div>
                            <div className="col-12 col-lg-6 believe-text">
                                <div className="believe-tick-text">
                                    <img src={tickpng} alt="" className="tickpng"/><p>That government should be of laws rather than of men,</p>
                                </div>
                                <div className="believe-tick-text">
                                    <img src={tickpng} alt="" className="tickpng"/><p>That earth’s great treasure lies in human personality,</p>
                                </div>
                                <div className="believe-tick-text">
                                    <img src={tickpng} alt="" className="tickpng"/><p>And that service to humanity is the best work of life.</p>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
    );
};


export default About;
