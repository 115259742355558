import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './TopContainer.css';
import logo from './assets/brand/jci.png';
import managerLogo from './assets/images/bizonancelogo.png';
import memberform from './assets/Member Registration Form_20241211_120040_0000.pdf';

const TopContainer = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [dropdownsOpen, setDropdownsOpen] = useState({
    about: false,
    team: false,
    event: false,
    program: false,
    workingarea: false,
    download: false,
  });

  const menuRef = useRef(null);

  // Toggle mobile menu
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  // Toggle specific dropdown
  const toggleDropdown = (dropdown) => {
    setDropdownsOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  // Handle dropdown hover for desktop view
  const handleHover = (dropdown, isHovered) => {
    if (!isMobile) {
      setDropdownsOpen((prev) => ({
        ...prev,
        [dropdown]: isHovered,
      }));
    }
  };

  // Scroll to top and close menu (if mobile)
  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
      <div className="top-container">
        {/* Main Logo */}
        <Link to="/" onClick={handleNavClick}>
          <img src={logo} alt="Logo" />
        </Link>

        {/* Navbar */}
        <nav className="navbar navbar-expand-lg navbar-light bg-light" ref={menuRef}>
          <div className="container-fluid">
            {/* Sidebar (collapsible on mobile) */}
            <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
              <div className="sidebar-wrapper">
                {/* Sidebar Logo (visible in mobile view) */}
                <img src={logo} alt="Sidebar Logo" className="side-bar-logo" />

                {/* Navigation Items */}
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/" onClick={handleNavClick}>
                      Home
                    </NavLink>
                  </li>

                  <li
                      className="nav-item dropdown"
                      onClick={() => toggleDropdown('about')}
                      onMouseEnter={() => handleHover('about', true)}
                      onMouseLeave={() => handleHover('about', false)}
                  >
                  <span className="nav-link dropdown-toggle" id="aboutDropdown">
                    About Us
                  </span>
                    {dropdownsOpen.about && (
                        <ul className="dropdown-menu show">
                          <li>
                            <NavLink className="dropdown-item" to="/About" onClick={handleNavClick}>
                              About JCI
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/AboutJCI" onClick={handleNavClick}>
                              JCI Amravati
                            </NavLink>
                          </li>
                          <li>
                            <a className="dropdown-item" href="https://jciindia.in/#/" onClick={handleNavClick}>
                              JCI India
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="https://jci.cc/#/home" onClick={handleNavClick}>
                              JCI Global
                            </a>
                          </li>
                        </ul>
                    )}
                  </li>

                  <li
                      className="nav-item dropdown"
                      onClick={() => toggleDropdown('team')}
                      onMouseEnter={() => handleHover('team', true)}
                      onMouseLeave={() => handleHover('team', false)}
                  >
                  <span className="nav-link dropdown-toggle" id="teamDropdown">
                    Team
                  </span>
                    {dropdownsOpen.team && (
                        <ul className="dropdown-menu show">
                          <li>
                            <NavLink className="dropdown-item" to="/SuperHeroes" onClick={handleNavClick}>
                              JCI Superheroes 2025
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/Past Presidents" onClick={handleNavClick}>
                              Past Presidents
                            </NavLink>
                          </li>
                        </ul>
                    )}
                  </li>

                  <li
                      className="nav-item dropdown"
                      onClick={() => toggleDropdown('event')}
                      onMouseEnter={() => handleHover('event', true)}
                      onMouseLeave={() => handleHover('event', false)}
                  >
                  <span className="nav-link dropdown-toggle" id="eventDropdown">
                    Event
                  </span>
                    {dropdownsOpen.event && (
                        <ul className="dropdown-menu show">
                          <li>
                            <NavLink className="dropdown-item" to="/" onClick={handleNavClick}>
                              National Events
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/" onClick={handleNavClick}>
                              Zone Events
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/" onClick={handleNavClick}>
                              LO Events
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/" onClick={handleNavClick}>
                              Awards Bidding Portal
                            </NavLink>
                          </li>
                        </ul>
                    )}
                  </li>

                  <li
                      className="nav-item dropdown"
                      onClick={() => toggleDropdown('program')}
                      onMouseEnter={() => handleHover('program', true)}
                      onMouseLeave={() => handleHover('program', false)}
                  >
                  <span className="nav-link dropdown-toggle" id="programDropdown">
                    Program
                  </span>
                    {dropdownsOpen.program && (
                        <ul className="dropdown-menu show">
                          <li>
                            <NavLink className="dropdown-item" to="/" onClick={handleNavClick}>
                              National Program
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/" onClick={handleNavClick}>
                              Community Development Reporting
                            </NavLink>
                          </li>
                        </ul>
                    )}
                  </li>

                  <li
                      className="nav-item dropdown"
                      onClick={() => toggleDropdown('workingarea')}
                      onMouseEnter={() => handleHover('workingarea', true)}
                      onMouseLeave={() => handleHover('workingarea', false)}
                  >
                  <span className="nav-link dropdown-toggle" id="workingareaDropdown">
                    Working Areas
                  </span>
                    {dropdownsOpen.workingarea && (
                        <ul className="dropdown-menu show">
                          <li>
                            <NavLink className="dropdown-item" to="/Workmanagement" onClick={handleNavClick}>
                              Management
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/Workbusiness" onClick={handleNavClick}>
                              Business
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/Workcommunity" onClick={handleNavClick}>
                              Community
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/Workdevelopment" onClick={handleNavClick}>
                              International Growth and Development
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/Worktraining" onClick={handleNavClick}>
                              Training
                            </NavLink>
                          </li>
                        </ul>
                    )}
                  </li>

                  <li className="nav-item">
                    <NavLink className="nav-link" to="/Gallery" onClick={handleNavClick}>
                      Gallery
                    </NavLink>
                  </li>

                  <li
                      className="nav-item dropdown"
                      onClick={() => toggleDropdown('download')}
                      onMouseEnter={() => handleHover('download', true)}
                      onMouseLeave={() => handleHover('download', false)}
                  >
                  <span className="nav-link dropdown-toggle" id="downloadDropdown">
                    Downloads
                  </span>
                    {dropdownsOpen.download && (
                        <ul className="dropdown-menu show">
                          <li>
                            <a
                                className="dropdown-item"
                                href={memberform}
                                download="Member Registration Form_20241211_120040_0000.pdf"
                                onClick={handleNavClick}
                            >
                              Member registration form
                            </a>
                          </li>
                        </ul>
                    )}
                  </li>
                </ul>

                {/* Mobile-specific additional content */}
                {isMobile && (
                    <div className="sidebar-icons">
                      <div className="member-login">
                    <span>
                      <NavLink to="/Form" onClick={handleNavClick}>
                        Member Registration
                      </NavLink>
                    </span>
                      </div>
                      <div className="icons-div">
                        <a
                            href="https://www.facebook.com/people/JCI-Amravati-Estd-1959/100088209802621/"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleNavClick}
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a
                            href="https://www.instagram.com/jciamravatisince1959/"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleNavClick}
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                        <a
                            href="https://www.youtube.com/@jciamravatisince1959"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleNavClick}
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </div>
                    </div>
                )}

                {/* "Designed & Managed by" section at the bottom */}
                <div className="sidebar-managed">
                  <span>Designed & Managed by</span>
                  <img src={managerLogo} alt="Managed by Logo" className="managed-logo" />
                </div>
              </div>
            </div>

            {/* Toggle Button for Mobile */}
            <button
                className="navbar-toggler"
                type="button"
                onClick={toggleMenu}
                aria-controls="navbarNav"
                aria-expanded={isMenuOpen}
                aria-label="Toggle navigation"
            >
              <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </button>
          </div>
        </nav>

        {/* Desktop Social Icons */}
        {!isMobile && (
            <div className="social-icons">
          <span>
            <NavLink to="/Form" onClick={handleNavClick}>
              Member Registration
            </NavLink>
          </span>
              <a
                  href="https://www.facebook.com/people/JCI-Amravati-Estd-1959/100088209802621/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleNavClick}
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                  href="https://www.instagram.com/jciamravatisince1959/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleNavClick}
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                  href="https://www.youtube.com/@jciamravatisince1959"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleNavClick}
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
        )}
      </div>
  );
};

export default TopContainer;
